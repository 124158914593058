.editError 
{
font-style:italic;
color:red;        
}

input[required]:invalid + label:before , input[pattern]:invalid + label:before
{ 
color:red; 
content: ' \02639'; 
font-size: 24px;
position: relative;
top: -5px;        
left: 10px;      
}

input[required]:valid  + label:before , input[pattern]:valid + label:before
{ 
color:green; 
content: ' \0263a'; 
font-size: 30px;
position: relative;
top: -9px;  
left: 5px;      
}                            

ol
{
list-style: none;
margin: 0;
padding :0;
}
ol li
{
clear: both;
margin: 0 0 10px 0;
padding: 0;
min-height: 20px;       
}
.field
{
float:left;      
width: 10em;
}

input
{
float:left;        
}
      
.entity, image
{
float:left; 
display:inline;
}

.image
{
padding:1em;
width:50px; height:50px;
}

.emoticon
{
width:0em;
}

.input-box
{
display: inline-flex;
}  


.PROFILE-VIEW-container
{
    display: inline-block;
    background-color: #285171;
    padding: 3em;
    border-radius: 5px;
}