.WAITING-margin {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 100;
  padding: 5px;
  border-radius: 50%;
  background: #032d43;
  margin-left: -60px;
  margin-top: -60px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.WAITING-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1.3em;
  color: white;
  z-index: 101; /* Ensure text is above loader */
}

.WAITING-loader {
  border: 16px solid rgba(255, 255, 255, 0.3);
  border-top: 16px solid #181818;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: WAITING-spin 3s linear infinite; /* Increased speed */
}

@keyframes WAITING-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

  