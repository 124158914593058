.POSTER-container {
    display: inline-block;
    margin: 16px;
    padding: 16px;
    background-color: #285171;
    border-radius: 5px;
    text-align: left;
    border: #2196f3 1px solid;
    width: 320px;

    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
    color: #cccccc;
}

.POSTER-container a {
    color: #cccccc;
    text-decoration: none;
}

.POSTER-container a:hover {
    color: #2196f3;
    ;
}


.POSTER-title {
    margin-block-end: 8px;
    text-transform: uppercase;
    color: #9d9d9d;
    font-size: 40px;
}

.POSTER-subtile {
    margin-block-start: 0px;
    font-size: 27px;
}

.POSTER-image-container {
    text-align: center;
    background-color: #cccccc;
    display: flex;
    justify-content: center;
}

.POSTER-image {
    max-width: 100%;
}

.POSTER-comments-container,
.POSTER-tags-container,
.POSTER-moreinfo-container,
.POSTER-datetime-container {
    margin: 16px 0;
}

.POSTER-datetime-container {
    font-size: 20px;
    font-weight: bold;
}

.POSTER-moreinfo-container {
    display: flex;
    flex-flow: wrap;
    gap: 1em;
}

.POSTER-weblink {
    color: #9292ff;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
}


.POSTER-tooltip {
    position: relative;
    display: inline-block;
    background-color: black;
    padding: 0px 8px;
    border-radius: 3px;
    font-weight: bold;
    color: #9292ff;
    font-size: 20px;
}

.POSTER-tooltip .POSTER-tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: black;
    color: #ccc;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -110px;
    border: 3px solid #2196f3;
}

.POSTER-tooltip .POSTER-tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #2196f3 transparent transparent transparent;
}

.POSTER-tooltip:hover .POSTER-tooltiptext {
    visibility: visible;
}



.POSTER-comments-container {
    font-style: italic;
    text-shadow: 0 0 2px purple;
    padding-bottom: 0.5em;
}

.POSTER-comments-container a {
    color: aqua;
}


.POSTER-tags-container {}

.POSTER-region-container {}

.POSTER-location-image {
    width: 32px;
    height: 32px;
    vertical-align: middle;
}

.POSTER-label-container {}

.POSTER-label,
.POSTER-location {
    border-radius: 5px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 4px 8px;
    background-color: #1976d2;
    color: white;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    height: 32px;
    line-height: 32px;
    border: 1px solid #2196f3;
}

.POSTER-author {}

.POSTER-author-container {}

.POSTER-social {}

.POSTER-share {}

.POSTER-anchor-share {
    background-color: rgb(24, 119, 252);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    margin-left: 16px;
    margin-top: 16px;

}